.loader {
  border: 16px solid #f3f3f3; 
  border-top: 16px solid rgb(235 211 125); 
  border-radius: 50%; 
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite; 
  margin: 100px auto; 
}

/* / Animation for spinning / */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
