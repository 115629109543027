@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Montserrat" !important;
}

.fontRoboto {
  font-family: "Roboto" !important;
}

.horizontal_flip {
  transform: scaleX(-1);
}

.margin-top{
  @media (min-width:845px) {
    margin-top: 25%;
  }
}

.MuiBox-root {
  padding: 0 !important;
}
.formLayout {
  display: flex;

  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--gray-light-medium, #ddd);
  /* opacity: var(--sds-size-stroke-border); */
  background: #fefefe;
}
.modalFormDivs {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  opacity: 1;
}
.labelsDivs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}
.formsLables {
  color: #273240;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.formsInputs {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;

  overflow: hidden;
  flex-shrink: 0;

  padding: 12px 16px;
  text-align: left;
  font-size: 16px;
  color: #272727;

  @media (min-width: 768px) {
    min-width: 240px;
  }
}

.submitBtn {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 4px;
  background: var(--main-solid-gold, #ebd37d);
}
.discardBtn {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid var(--gray-dark, #333);
}
.btnOutlined {
  display: flex;
  border: 1px solid #ebd37d;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #ebd37d;
  font-size: 16px;
  color: #030303;
}
.borderNone {
  border-right: 1px solid #00000033;
  @media (max-width: 844px) {
    border: none;
    padding-right: 0px;
  }
}
